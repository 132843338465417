import React from 'react'
import { Link } from 'gatsby'
import { LayoutWithImage } from '../../../layouts'
import Seo from '../../../components/seo'
import { CONTENT } from '../../../content'
import BreadcrumbList from '../../../components/BreadcrumbList/BreadcrumbList'
import BreadcrumbItem from '../../../components/BreadcrumbList/BreadcrumbItem'

const Enfant = () => (
  <LayoutWithImage>
    <Seo
      title={CONTENT.CONSULTATION.AUDIENCE.CHILDREN.meta.title}
      description={CONTENT.CONSULTATION.AUDIENCE.CHILDREN.meta.description}
    />

    <div className="wrapper">
      <header>
        <h1>{CONTENT.CONSULTATION.AUDIENCE.CHILDREN.title}</h1>
      </header>

      <div>
        {CONTENT.CONSULTATION.AUDIENCE.CHILDREN.content.map(
          (content, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
          ),
        )}
      </div>
    </div>

    <BreadcrumbList>
      <>
        <BreadcrumbItem position={1}>
          <Link className="link" itemProp="item" to="/">
            <span itemProp="name">Accueil</span>
          </Link>
        </BreadcrumbItem>
        ›
        <BreadcrumbItem position={2}>
          <Link className="link" itemProp="item" to="/consultation">
            <span itemProp="name">Consultation</span>
          </Link>
        </BreadcrumbItem>
        ›
        <BreadcrumbItem position={3}>
          <Link
            className="link"
            itemProp="item"
            to="/consultation/public-accueilli"
          >
            <span itemProp="name">Public accueilli</span>
          </Link>
        </BreadcrumbItem>
        ›
        <BreadcrumbItem position={4}>
          <span itemProp="name">Enfants</span>
        </BreadcrumbItem>
      </>
    </BreadcrumbList>
  </LayoutWithImage>
)

export default Enfant
